<!--
 * ======================================
 * 说明： 任课管理
 * 作者： Silence
 * 文件： instructor.vue
 * 日期： 2023/8/2 13:34
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div class="h-full">
    <Scrollbar>
      <Table :hasAction="true" :options="handleOptions" :loadData="handleLoadData" title="任课管理" ref="table">
        <template #search>
          <el-form :inline="true" :model="formSearch" label-width="80px">
            <el-form-item label="学期">
              <el-select class="w-full" v-model="formSearch.semesterId">
                <el-option v-for="item in semesterOptions" :key="item.value" :label="item.semester_name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="班级">
              <el-cascader clearable v-model="classroom" @change="handleClasses" :options="classesTree" :props="{value:'id',label:'name'}" :show-all-levels="false"></el-cascader>
            </el-form-item>
            <el-form-item label="科目">
              <el-select clearable class="w-full" v-model="formSearch.subjectId" filterable>
                <el-option v-for="(item,index) in subjectOptions" :label="item['name']" :value="item['id']" :key="index"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="教师">
              <el-select clearable class="w-full" v-model="formSearch.teacherId" filterable>
                <el-option v-for="(item,index) in teacherOptions" :label="item['real_name']" :value="item['id']" :key="index"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleRefresh">查询</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template #action>
          <div>
            <el-button type="primary" @click="handleCreate" icon="el-icon-plus">添加任课</el-button>
            <el-button type="success" @click="handleExport" icon="el-icon-download">导入模板</el-button>
            <el-upload
              action=""
              :show-file-list="false"
              style="display: contents"
              accept=".xls,.XLS,.xlsx,.XLSX"
              :http-request="handleImport"
            >
              <el-button  icon="el-icon-upload2" type="primary" class="ml-2"
              >批量导入
              </el-button>
            </el-upload>
          </div>
        </template>
        <template #handle="{index,row}">
          <el-button type="text" icon="el-icon-edit" @click="handleLogin(row)">登录</el-button>
          <el-button type="text" @click="handleEdit(row)" icon="el-icon-edit">修改</el-button>
          <el-button type="text" @click="handleDelete(row)" icon="el-icon-delete">删除</el-button>
        </template>
      </Table>
    </Scrollbar>
  </div>
</template>
<script>
import edit from './components/instructorEdit.vue'
import mixin from "@/mixin/execlExportImport";
import Cookies from "js-cookie";
export default {
  name: '',
  components: {edit},
  mixins: [mixin],
  props: {},
  data() {
    return {
      classroom:[],
      classesTree:[],
      subjectOptions:[],
      teacherOptions:[],
      semesterOptions:[],
      options: {
        header: [],
        search: []
      },
      //搜索表单结构
      formSearch: {
        classId:'',
        subjectId:'',
        teacherId:'',
        semesterId:''
      },
    }
  },
  computed: {},
  created() {},
  async mounted() {
    await this.getClassesTree();
    await this.handleLoadSubjects();  // 获取所有科目
    await this.handleLoadTeachers();  // 获取所有教师
    await this.handleLoadSemesterOptions();

    await this.handleRefresh();
  },
  methods: {
    async handleLoadSemesterOptions(){
      await this.$cloud.get("semester/list",{
        size:99999,
        page:1,
      }).then(res => {
        this.semesterOptions = res.children;
      })
    },

    // 获取班级待选树状数据
    async getClassesTree(){
      await this.$cloud.post('/classes/list/tree').then(res=>{
        this.classesTree = res
      })
    },
    handleClasses(){
      this.formSearch['classId'] = ''
      if(this.classroom.length){
        this.formSearch['classId'] = this.classroom[1]
      }
    },
    // 获取所有科目
    async handleLoadSubjects(){
      await this.$cloud.get('/subject/list',{
        page:1,
        size:99999,
      }).then(res=>{
        this.subjectOptions = res.children
      })
    },
    // 获取所有教师
    async handleLoadTeachers(){
      await this.$cloud.get('/teacher/list',{
        page:1,
        size:99999,
      }).then(res=>{
        this.teacherOptions = res.children
        console.log('teacherOptions:',this.teacherOptions)
      })
    },

    handleOptions(table) {
      console.log("获取表格数据源", table)
      //获取分页数据结构
      this.$cloud.get("header/teaching/instructor").then(res => {
        let {header, search} = res;
        table.setHeader(header);
        table.setSearch(search);
      }).catch(err => {
        table.errMsg(err)
      })
    },
    handleLoadData(table, {size, page}, search = {}) {
      //获取分页数据结构
      this.$cloud.get("teaching/list", {size, page,...this.formSearch}).then(res => {
        table.setData(res);
      }).catch(err => {
        table.errMsg(err)
      })
    },
    // 新增任课关系
    handleCreate() {
      this.$cloud.dialog({
        title: '添加任课',
        data: {},
        component: edit,
        success: '添加成功！',
        warning: '添加失败！',
        refresh: this.handleRefresh
      })
    },
    handleEdit(item) {
      this.$cloud.dialog({
        title: '更新任课',
        data: item,
        component: edit,
        success: '更新成功！',
        warning: '更新失败！',
        refresh: this.handleRefresh
      })
    },
    handleDelete(item) {
      console.log("删除",item)
      this.$confirm('此操作将永久删除该任课吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$cloud.post("teaching/remove", {id: item.id}).then(() => {
          this.$message.success('删除成功！')
          this.handleRefresh()
        })
      })
    },

    // 导出模板
    handleExport(){
      let tHeader = ["班级编号","班级名称",'教学科目','任课老师','手机号码'];
      this.exportElxs({
        header: tHeader, //这里应该是算第二行的表头
        data: [], //数据
        filename: `任课导入模板`,
        autoWidth: true,
        bookType: "xlsx",
      });
    },
    // 导入模板
    handleImport(e){
      this.httpRequest(e,(data)=>{
        if(data && data.length){
          let params = {
            list:data.map(item=>{
              return {
                subjectName: item['教学科目'],
                mobile:String(item['手机号码']),
                classNumber: item['班级编号'],
              }
            })
          }
          this.$cloud.post('teaching/import',params).then(res=>{
            this.$message.success('导入成功!')
            this.handleRefresh()
          })
        }
      })
    },

    // 刷新
    handleRefresh() {
      this.$nextTick(() => {
        if(this.$refs['table'].resetPage){
          this.$refs['table'].resetPage()
        }
        if(this.$refs['table'].handleLoadData){
          this.$refs['table'].handleLoadData()
        }
      })
    },
    // 一键登录
    handleLogin(row){
      console.log("登录",row)
      if(!row.teacher_real_name){
        this.$message.error("没有老师信息")
        return
      }
      let teacherId = row.teacher_id
      this.$message.info("登录中")
      this.$cloud.post("teacher/fast/login", {teacherId}).then(res=>{
        console.log('res',res)
        this.$message.success("登录成功")
        Cookies.remove("hankun_cloud_template_token");
        Cookies.set("hankun_cloud_template_token",res.token)
        window.location.replace('/pages/web/panel')
        // this.$cloud.go("/pages/web/panel");
      })
    }


  },
}
</script>
<style lang="scss" scoped></style>
