<!--
 * ======================================
 * 说明： 任课  新增  修改
 * 作者： Silence
 * 文件： instructorEdit.vue
 * 日期： 2023/8/10 23:18
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <el-form ref="form" :model="form" label-width="80px" :rules="rules">
    <el-form-item label="学期" prop="semesterId">
      <el-select :disabled="!!form.id" class="w-full" v-model="form.semesterId">
        <el-option v-for="(item,index) in semesterOptions" :label="item.semester_name" :value="item.id"
                   :key="index"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="学科" prop="subjectId">
      <el-select class="w-full" v-model="form.subjectId" @change="handleChange">
        <el-option v-for="(item,index) in subjectOptions" :label="item['name']" :value="item['id']"
                   :key="index"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="老师" prop="teacherId">
      <el-select filterable class="w-full" v-model="form.teacherId" @change="handleChangeTeacher">
        <el-option v-for="(item,index) in teacherOptions" :label="item['real_name']" :value="item['id']"
                   :key="index">
          <div class="flex">
            <div>{{item.real_name}}</div>
            <div class="ml-auto">{{item.mobile}}</div>
          </div>
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item v-show="!form.id" label="班级" prop="classList">
      <el-cascader class="w-full" v-model="grade" :options="classesTree"
                   :props="{value:'id',label:'name',multiple:true}" collapse-tags></el-cascader>
    </el-form-item>
    <el-form-item v-show="!!form.id" label="班级" prop="classList">
      <el-cascader class="w-full" v-model="grade" :options="classesTree" :props="{value:'id',label:'name'}"
                   collapse-tags></el-cascader>
    </el-form-item>
  </el-form>
</template>
<script>
export default {
  name: '',
  components: {},
  props: {
    value: Object,
  },
  data() {
    return {
      aaa: [],
      semesterOptions: [],
      subjectOptions: [],
      teacherOptions: [],
      grade: [],
      classesTree: [],
      form: {
        subjectId: '',
        teacherId: [],
        classList: [],
        semesterId: [],
      },
      rules: {
        subjectId: [{required: true, message: '请选择科目', trigger: 'blur'}],
        teacherId: [{required: true, message: '请选择教师', trigger: 'blur'}],
        classList: [{required: true, message: '请现在班级', trigger: 'blur'}],
        semesterId: [{required: true, message: '请选择学期', trigger: 'blur'}],
      },
    }
  },
  computed: {},
  created() {
  },
  async mounted() {
    await this.handleLoadSemester();  // 获取所有学期
    await this.handleLoadSubjects();  // 获取所有科目
    await this.handleLoadTeachers();  // 获取所有教师
    await this.getClassesTree();  // 获取所有班级


    if (this.value.id) {
      await this.handleLoadForm(this.value.id)
    } else {
      this.form = {
        semesterId: [],
        subjectId: '',
        teacherId: [],
        classList: [],
      }
    }
  },
  methods: {
    handleChange(e) {
      console.log("e", e)
      this.$set(this.form, 'subjectId', e)
      this.$forceUpdate()
    },
    handleChangeTeacher() {
      this.$forceUpdate()
    },
    async handleLoadSemester() {
      await this.$cloud.get('semester/list', {
        page: 1,
        size: 99999
      }).then(res => {
        this.semesterOptions = res.children
      })
    },

    async handleLoadForm(id) {
      await this.$cloud.get('teaching/detail', {id}).then(res => {
        this.form = res
        this.form.semesterId = res.semester_id
        this.form.teacherId = res.teacher_id
        this.form.classList = res.class_id
        this.form.subjectId = res.subject_id
        let level = ''
        this.classesTree.map(item => {
          if (item.children && item.children.length) {
            item.children.map(part => {
              console.log(part, this.form.class_id)
              if (part.id === this.form.class_id) {
                level = item.id
              }
            })
          }
        })
        this.grade = [level, this.form.class_id];  //选中的班级
      })
    },
    // 获取所有科目
    async handleLoadSubjects() {
      await this.$cloud.get('/subject/list', {
        page: 1,
        size: 99999
      }).then(res => {
        this.subjectOptions = res.children
      })
    },
    // 获取所有教师
    async handleLoadTeachers() {
      await this.$cloud.get('/teacher/list', {
        status: 1,
        page: 1,
        size: 99999
      }).then(res => {
        this.teacherOptions = res.children
      })
    },
    // 获取班级待选树状数据
    async getClassesTree() {
      await this.$cloud.post('/classes/list/tree').then(res => {
        this.classesTree = res
      })
    },

    // 执行数据提交接口
    handleSubmit() {
      if (this.form.id) {
        this.form.class_id = this.grade[1]
      } else {
        this.form.classList = this.grade.map(item => item[1]);
      }

      this.form.semester_id = this.form.semesterId
      this.form.teacher_id = this.form.teacherId
      this.form.subject_id = this.form.subjectId

      return new Promise((resolve, reject) => {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            let api = '/teaching/create'
            //判断是否存在ID，如果存在ID，则是修改方法
            if (this.form.id) {
              api = '/teaching/update'
            }
            this.$cloud.post(api, this.form).then(res => {
              resolve(true)
            }).catch(err => {
              reject(false)
            })
          } else {
            reject(false)
          }
        })
      })
    }
  },
}
</script>
<style lang="scss" scoped></style>
